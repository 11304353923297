import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import { SupportPageTypes } from '@shared/types/umbraco/content/supportPage';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import WideBanner from 'gatsby-theme-husky/src/components/WideBanner';
import SimpleBanner from 'gatsby-theme-husky/src/components/SimpleBanner';
import TextSection from 'components/TextSection';
import ProductListingBanner from 'components/ProductListingBanner';
import InstructionsStepList from 'components/InstructionsStepList';
import InfoBanner from 'components/InfoBanner';
import ContactForm from 'components/ContactForm';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import SignUp from 'components/SignUp';

const elements = {
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} />
          )
        }
        button={button}
      />
    );
  },
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Instructions List': ({ properties }, keyId) => (
    <InstructionsStepList key={keyId} properties={properties} />
  ),
  'Contact Form Section': ({ properties }, keyId) => <ContactForm key={keyId} {...properties} />,
};

const SupportPage: FC<SupportPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
    relatedProducts,
  },
}) => {
  const { waiSettings, singupFormBaner, siteSettings } = defaultCompositions;
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
      }}
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}
      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{
          ...{
            relatedProducts,
            waiSettings,
            singupFormBaner,
            siteSettings,
            usePriceSpider: siteSettings?.usePriceSpider,
            isEanProductId: siteSettings?.isEanProductId,
          },
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query allSupportQuery($link: String, $relatedProductLinks: [String]) {
    relatedProducts: allProduct(filter: { link: { in: $relatedProductLinks } }) {
      nodes {
        link
        ean
        useRedirects
        tags {
          isCategory
          isFamily
          color {
            label
          }
          isLabel
          title
        }
        title
        cardDescription
        shortDescription
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    page: allSupport(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
        }
        banner {
          ...FragmentSimpleBanner
        }
        body {
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TSectionwithcarousel {
            ...FragmentSectionWithCarousel
          }
          ... on TProductListingBanner {
            ...FragmentProductListingBanner
          }
          ... on TInstructionsList {
            ...FragmentInstructionsList
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TContactFormSection {
            ...FragmentContactForm
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
        }
      }
    }
  }
`;

export default SupportPage;
